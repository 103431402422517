import store from "@/store";
import destroyLocalStorage from "@/mixins/destroyLocalStorage";

export const lazyLoad = (view) => {
    return () => import(`@/components/navRoutes/${view}.vue`)
}

export const lazyLoadPages = (view) => {
    return () => import(`@/components/detailPages/${view}.vue`)
}

export const lazyLoadNavRoutes = (view) => {
    return () => import(`@/components/navRoutes/${view}.vue`)
}

export const lazyLoadView = (view) => {
    return () => import(`@/views/${view}.vue`)
}

export const ifKYC = (to, from, next) => {
    const kyc_status = localStorage.getItem('cStatus')
    if (kyc_status == "Active") {
        localStorage.removeItem("nextRoute");
        next()
    } else {
        next({ name: "kyc" })
    }
}

export const isRejected = (to, from, next) => {
    const kyc_status = localStorage.getItem('cStatus').toLowerCase();

    if (kyc_status == "rejected") {
        next({ name: 'kycRejected' })
    } else if (kyc_status == "suspended") {
        next({ name: 'kycSuspended' })
    } else if (kyc_status == "pending") {
        next({ name: "kycPending" })
    } else if (kyc_status == "preprocessed") {
        next({ name: "kycPending" })
    } else if (kyc_status == "active") {
        next({ name: "/" })
    } else {
        next()
    }
}

// redirect when a user is already logged in
// if accessToken and client ID is active a client is forced to stay on the client Office
export const alreadyLogin = (to, from, next) => {
    if (localStorage.getItem("cID") && localStorage.getItem("aen"))
        next({ path: '/' })
    else
        next();
    return
};

// requirement for user to log on to the authenticated pages
export const ifAuthenticated = (to, from, next) => {
    // check if client ID exists in localstorage 
    // check if accessToken is not equal to null in memory
    if (store.state.onboarding.clientID && store.state.onboarding.accessToken && store.state.onboarding.serverStatus < 1) {
        store
            .dispatch("profile/kycStep")
            .then((response) => {
                localStorage.setItem(
                    "steps",
                    JSON.stringify(response.data.data.kyc_remaining_steps)
                );
                localStorage.setItem(
                    "cStatus",
                    response.data.data.kyc_status
                );
                localStorage.removeItem("nextRoute");
            })
            .catch((error) => {
                return error;
            });
        next()
    } else {
        logUserOut(next);
    }
}

// check if a client was logged out forcefully
// then store there present page before logout, once a client logged in again take them to there previous page before forcelly logging out.
export const setNextRoute = () => {
    let url_ID = window.location.href;
    let newURL = new URL(url_ID);
    localStorage.setItem("nextRoute", newURL)
}

// logout 
// all activies is ended and the client logged out
const logUserOut = (next) => {
    destroyLocalStorage();
    setNextRoute()
    next({ name: 'login' });
}