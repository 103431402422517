// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
  bioDetails: [],
  AddBankAcct: [],
  ChangePwd: [],
  itemPerPage: 10,
  numberOfRows: 0,
  currentPage: 1,
  activitiesType: "",
  dateRange: {
    startDate: '',
    endDate: '',
  },
};
const getters = {};

const actions = {
  clientsBioDetails: (context) => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/profile`, data).then(response => {
        resolve(response)
        context.commit('setClientBio', response.data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  bankingDetails: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/profile-banking-details`, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addBankAcct: (context, data) => {

    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/profile-add-bank-account`, data).then(response => {
        context.commit('setAddBankAcct', response.data.data);
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  addBankAcctStaging: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/profile-add-bank-account-new`, data).then(response => {
        context.commit('setAddBankAcct', response.data.data);
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  changePwd: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/profile-change-password`, data).then(response => {
        context.commit('setChangePwd', response.data.data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  yourActivity: (context) => {
    const data = {
      client_id: localStorage.getItem("cID"),
      session: localStorage.getItem("aen"),
      start_date: state.dateRange.startDate ? state.dateRange.startDate : "",
      end_date: state.dateRange.endDate ? state.dateRange.endDate : "",
      type: state.activitiesType
    };
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/profile-activities/${state.itemPerPage}`, data).then(response => {
        resolve(response)
        // send all response to store
        context.commit('setNumbersOfRow', response.data.num_rows)
      }).catch(error => {
        reject(error)
      })
    })
  },

  kycStep: () => {
    const data = {
      client_id: localStorage.getItem("cID"),
      session: localStorage.getItem("aen"),
    };
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/steps`, data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
};

const mutations = {
  // filter by date
  filterRange: (state, data) => { state.dateRange = data },
  setAddBankAcct(state, data) {
    state.AddBankAcct = data
  },
  setChangePwd(state, data) {
    state.ChangePwd = data
  },
  setClientBio(state, data) {
    state.bioDetails = data
  },
  // commit numbers of rows
  setNumbersOfRow: (state, data) => (state.numberOfRows = data),
  // increment itemPerScroll
  setItemPerPage: (state) => (state.itemPerPage += 10),
  decreaseItemPerPage: (state) => (state.itemPerPage -= 10),
  setCurrentPage: (state) => (state.currentPage += 1),
  removeCurrentPage: (state) => (state.currentPage -= 1),
  returnItemToTen: (state, data) => (state.itemPerPage = data),
  returnToPageOne: (state, data) => (state.currentPage = data),
  setActivitiesType: (state, data) => (state.activitiesType = data)
};

export default {
  //export all the listed properties
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
