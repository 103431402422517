// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
    Announcement: [],
    numberOfRows: 0,
    itemPerPage: 10,
    currentPage: 1,
    dateRange: {
        startDate: '',
        endDate: '',
    },
};

const getters = {};

const actions = {
    announcement: (context) => {
        const data = {
            client_id: localStorage.getItem('cID'),
            session: localStorage.getItem('aen'),
            start_date: state.dateRange.startDate ? state.dateRange.startDate : "",
            end_date: state.dateRange.endDate ? state.dateRange.endDate : ""
        }

        return new Promise((resolve, reject) => {
            onboardingHttpClient.post(`/announcements/${state.itemPerPage}`, data).then(response => {
                // send all response to store
                context.commit('setNumbersOfRow', response.data.num_rows)
                resolve(response)
            })
                .catch(error => {
                    // reject all response to store
                    reject(error);
                })
        })
    },
};

const mutations = {
    // filter by date
    filterRange: (state, data) => { state.dateRange = data },
    // commit numbers of rows
    setNumbersOfRow: (state, data) => (state.numberOfRows = data),
    // increment itemPerScroll
    setItemPerPage: (state) => (state.itemPerPage += 10),
    decreaseItemPerPage: (state) => (state.itemPerPage -= 10),
    setCurrentPage: (state) => (state.currentPage += 1),
    returnItemToTen: (state, data) => (state.itemPerPage = data),
    returnToPageOne: (state, data) => (state.currentPage = data),
    removeCurrentPage: (state) => (state.currentPage -= 1),
};

export default {
    //export all the listed properties
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
