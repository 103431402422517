export default {
    created() {
        this.isModeSelected()
    },

    methods: {
        isModeSelected() {
            const currentTheme = localStorage.getItem('mode') === null ? null : localStorage.getItem('mode').toLowerCase();
            if (currentTheme === null) {
                localStorage.setItem('mode', 'Dark');
                document.documentElement.setAttribute('data-theme', 'dark');
            }
            else {
                document.documentElement.setAttribute('data-theme', currentTheme);
            }
        },
    }
}