// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
    promotion: [],
    numberOfRows: 0,
    itemPerPage: 10,
    currentPage: 1,
};

const getters = {};

const actions = {
    Promotion: (context) => {
        const data = {
            client_id: localStorage.getItem('cID'),
            session: localStorage.getItem('aen')
        }
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post(`/promotions/${state.itemPerPage}`, data).then(response => {
                // send all response to store
                context.commit('setPromotions', response.data.data)
                context.commit('setNumbersOfRow', response.data.num_rows)
                resolve(response)
            })
                .catch(error => {
                    context.commit("doNothing");
                    // reject all response to store
                    reject(error);
                })
        })
    },
};

const mutations = {
    // commit all announcement
    setPromotions: (state, data) => (state.promotion = data),
    // commit numbers of rows
    setNumbersOfRow: (state, data) => (state.numberOfRows = data),
    // increment itemPerScroll
    setItemPerPage: (state) => (state.itemPerPage += 10),
    decreaseItemPerPage: (state) => (state.itemPerPage -= 10),
    setCurrentPage: (state) => (state.currentPage += 1),
    removeCurrentPage: (state) => (state.currentPage -= 1),
    // commit nothing
    doNothing: (state) => (state.doNothing = null),
};

export default {
    //export all the listed properties
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
