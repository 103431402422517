// import helper controller functions
import { lazyLoadView, lazyLoadNavRoutes } from './controller';

export const partnership = {
    path: "/partnership",
    component: lazyLoadView('partnership'),
    children: [
        {
            path: "",
            name: "introBroker",
            component: lazyLoadNavRoutes('partnership/introBroker')
        },
        {
            path: "ib",
            name: "ib_index",
            component: lazyLoadNavRoutes('partnership/index')
        },
        {
            path: "clients",
            name: "ibClients",
            component: lazyLoadNavRoutes('partnership/clients')
        },
        {
            path: "viewWithdrawal",
            name: "viewWithdrawal",
            component: lazyLoadNavRoutes('partnership/viewWithdrawal')
        },
        {
            path: "cashout",
            name: "cashOut",
            component: lazyLoadNavRoutes('partnership/cashOut')
        },
        {
            path: "partners-banner",
            name: "partners-banner",
            component: lazyLoadNavRoutes('partnership/partnersBanner')
        }
    ]
}