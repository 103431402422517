// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
  social: [],
  sliderBanner: [],
};

const getters = {
  sliderBanner: state => state.sliderBanner
};

const mutations = {
  setBanner: (state, data) => state.sliderBanner = data
};

const actions = {
  CreateProvider: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen')
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post("/social/open-provider-account", data).then(response => {
        // send all response to store
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  CreateFollowers: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen')
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post("/social/open-account", data).then(response => {
        // send all response to store
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  isFollowers: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen')
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post("/social/is-follower", data).then(response => {
        // send all response to store
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  isProvider: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen')
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post("/social/is-provider", data).then(response => {
        // send all response to store
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  socialBanner: (context) => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen')
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post("/social/slider-banners", data).then(response => {
        context.commit('setBanner', response.data.data)
        // send all response to store
        resolve(response);
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },
};

export default {
  //export all the listed properties
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};