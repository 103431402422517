import Vue from "vue";
import VueRouter from "vue-router";
// dashboard
import { mainRoutes } from './main.route';
// import helper controller functions
import { alreadyLogin, lazyLoad } from './controller';

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: lazyLoad('onboarding/login'),
    beforeEnter: alreadyLogin,
  },
  {
    path: "/forgot-password",
    name: "forgotPwd",
    component: lazyLoad('onboarding/forgotPwd'),
    beforeEnter: alreadyLogin,
  },
  {
    path: "/register",
    name: "register",
    component: lazyLoad('onboarding/register')
  },
  {
    path: "/change-password",
    name: "changepwd",
    component: lazyLoad('onboarding/changePwd'),
    beforeEnter: alreadyLogin,
  },
  {
    path: "/change-number",
    name: "changeNumber",
    component: lazyLoad('onboarding/changeNumber')
  },
  {
    path: "*",
    name: "notFound",
    component: () => import("@/error/404.vue")
  },
  mainRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
