// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
    LegalDocuments: []
};

const getters = {};

const actions = {
    legalDocuments: (context) => {
        const data = {
            client_id: localStorage.getItem('cID'),
            session: localStorage.getItem('aen')
        }
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/legal-documents", data).then(response => {
                // send all response to store
                resolve(response)
            })
                .catch(error => {
                    context.commit("doNothing");
                    // reject all response to store
                    reject(error);
                })
        })
    },
};

const mutations = {
    // commit nothing
    doNothing: (state) => (state.doNothing = null),
};

export default {
    //export all the listed properties
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
