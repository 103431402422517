// import helper controller functions
import { isRejected, ifAuthenticated, lazyLoadNavRoutes, lazyLoadView } from './controller';
import { mainAuth } from './dashboard.route';
import { partnership } from './partnership.route';
import { profile } from './profile.route';
import { platform } from './platform.route';
import { social } from './social.route';

export const mainRoutes = {
  path: "",
  component: lazyLoadView('home'),
  beforeEnter: ifAuthenticated,
  // child route for home
  children: [
    mainAuth,
    partnership,
    profile,
    platform,
    social,
    {
      path: "/promotion",
      component: lazyLoadView('promotion'),
      children: [
        {
          path: "",
          name: "promotion",
          component: lazyLoadNavRoutes('promotion/index')
        }
      ]
    },
    {
      path: "/documents",
      component: lazyLoadView('document'),
      children: [
        {
          path: "",
          name: "documents",
          component: lazyLoadNavRoutes('documentation/home')
        }
      ]
    },
    {
      path: "/announcement",
      component: lazyLoadView('announcement'),
      children: [
        {
          path: "",
          name: "announcement",
          component: lazyLoadNavRoutes('announcements/home')
        }
      ]
    },
    {
      path: "/events",
      component: lazyLoadView('events'),
      children: [
        {
          path: "",
          name: "events",
          component: lazyLoadNavRoutes('events/events')
        }
      ]
    },
    { path: "/kyc", name: "kyc", component: lazyLoadNavRoutes('profiles/kyc'), beforeEnter: isRejected },
    { path: "/kyc_pending", name: "kycPending", component: lazyLoadNavRoutes('profiles/kycPending') },
    { path: "/kyc_rejected", name: "kycRejected", component: lazyLoadNavRoutes('profiles/kycRejected') },
    { path: "/kyc_suspend", name: "kycSuspended", component: lazyLoadNavRoutes('profiles/kycSuspended') }
  ]
}