// import helper controller functions
import { lazyLoadPages, lazyLoadView, lazyLoadNavRoutes, ifKYC } from './controller';

export const mainAuth = {
    path: "/",
    component: lazyLoadView('trading'),

    children: [
        {
            path: "",
            name: "dashboardWelcome",
            component: lazyLoadNavRoutes('trading/dashboard'),
        },
        {
            path: "/accounts",
            component: lazyLoadPages('account/accountPage'),
            children: [
                {
                    path: "",
                    name: "accounts",
                    beforeEnter: ifKYC,
                    component: lazyLoadNavRoutes('trading/accounts')
                },
                {
                    path: "interTransfer",
                    name: "interTransfer",
                    beforeEnter: ifKYC,
                    component: lazyLoadPages('account/interTransfer')
                },
                {
                    path: "openAccount",
                    name: "openAccount",
                    component: lazyLoadPages('account/openAccount')
                },
                {
                    path: ":id",
                    name: "accountDetails",
                    component: lazyLoadPages('account/accountDetails'),
                    props: true
                },
                {
                    path: "openAccount/:id",
                    name: "openAccountDetail",
                    component: lazyLoadPages('account/openAccountDetail'),
                    props: true
                },
                {
                    path: "accountProcessedHistory/:id",
                    name: "accountProcessedHistory",
                    component: lazyLoadPages('account/processedHistory'),
                    props: true
                }
            ]
        },
        // main route for deposit
        {
            path: "deposit",
            component: lazyLoadPages('deposits/depositPage'),
            beforeEnter: ifKYC,
            // child route for deposit
            children: [
                {
                    path: "",
                    name: "deposit",
                    component: lazyLoadNavRoutes('trading/deposit')
                },
                {
                    path: "select_account",
                    name: "selectAccount",
                    component: lazyLoadPages('deposits/selectAccount')
                },
                {
                    path: "deposit_details/:id",
                    name: "depositDetails",
                    component: lazyLoadPages('deposits/depositDetails'),
                    props: true
                },
                {
                    path: "wire_bank_details",
                    name: "wireBankDetails",
                    component: lazyLoadPages('deposits/wireBankingDetails'),
                    props: true
                },
                {
                    path: "instant_bank_details",
                    name: "instantBankDetails",
                    component: lazyLoadPages('deposits/instantBankingDetails'),
                    props: true
                },
                {
                    path: "instant_bank_success",
                    name: "instantBankSuccess",
                    component: lazyLoadPages('deposits/instantPaymentSuccess'),
                    props: true
                },
                {
                    path: "instant_bank_failed",
                    name: "instantBankFailed",
                    component: lazyLoadPages('deposits/instantPaymentFailed'),
                    props: true
                },
                {
                    path: "pos_bank_details",
                    name: "posBankDetails",
                    component: lazyLoadPages('deposits/posBankingDetails'),
                    props: true
                },
                {
                    path: "cash_bank_details",
                    name: "cashBankDetails",
                    component: lazyLoadPages('deposits/cashBankingDetails'),
                    props: true
                },
                {
                    path: "deposit_history",
                    name: "history",
                    component: lazyLoadPages('deposits/depositHistory')
                }
            ]
        },
        {
            path: "withdrawal",
            name: "with-drawal",
            component: lazyLoadNavRoutes('trading/withdrawal'),
        }
    ]
}