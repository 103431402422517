<template>
  <v-app>
    <v-main class="dashboard-bg">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
document.title = "MyRallyTrade";
import matchMedia from "./mixins/matchMedia";

export default {
  name: "App",

  mixins: [matchMedia],
};
</script>

<style lang="scss">
:root {
  --primary-color: #302ae6;
  --secondary-color: #536390;
  --font-color: #424242;
  --bg-color: #03b10a;
  --heading-color: #292922;
  --card-text-color: #33332c;
  --card-color: #fff;
  --loader-bg: #fff;
  --loader-border: #eee;
  --dashboard-bg: #f4f5fd;
  --icon_color: rgba(0, 0, 0, 0.54);
  --db-card-color: #fff;
  --atm-card: #0e0e23;
  --input-color: #1e293b;
}

[data-theme="dark"] {
  --primary-color: #9a97f3;
  --secondary-color: #818cab;
  --font-color: #e1e1ff;
  --bg-color: #0e0e23;
  --loader-bg: #0e0e23;
  --loader-border: #0e0e23;
  --dashboard-bg: #0e0e23;
  --heading-color: #e3f2fd;
  --card-text-color: #9b9ca7;
  --atm-card: #1e293b;
  --card-color: #1e293b;
  --db-card-color: #0e0e23;
  --input-color: #1e293b;
}
.dashboard-bg {
  background: var(--dashboard-bg);
  // height: 100vh;
  overflow-y: auto;
}
.theme--light.v-skeleton-loader .v-skeleton-loader__actions,
.theme--light.v-skeleton-loader .v-skeleton-loader__article,
.theme--light.v-skeleton-loader .v-skeleton-loader__card-heading,
.theme--light.v-skeleton-loader .v-skeleton-loader__card-text,
.theme--light.v-skeleton-loader .v-skeleton-loader__date-picker,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-text,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-three-line,
.theme--light.v-skeleton-loader .v-skeleton-loader__list-item-two-line,
.theme--light.v-skeleton-loader .v-skeleton-loader__table-heading,
.theme--light.v-skeleton-loader .v-skeleton-loader__table-tbody,
.theme--light.v-skeleton-loader .v-skeleton-loader__table-tfoot,
.theme--light.v-skeleton-loader .v-skeleton-loader__table-thead {
  background-color: var(--card-color) !important;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: var(--font-color);
  border: 1px solid var(--font-color) !important;
}
.theme--light.v-label {
  color: var(--font-color) !important;
}
.theme--light.v-icon {
  color: var(--font-color) !important;
}
.theme--light.v-input,
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: var(--font-color) !important;
}
.theme--light.v-list {
  background: var(--card-color) !important;
  color: var(--card-text-color) !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: var(--card-text-color) !important;
}
.theme--light.v-select .v-select__selections {
  color: var(--card-text-color) !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
tr {
  color: var(--card-text-color) !important;
  font-size: 17px !important;
}
@font-face {
  font-family: "MyWebFont";
  src: url("./assets/fonts/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2")
    format("woff2");
  font-display: swap;
  /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: "MyWebFont";
  src: url("./assets/fonts/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2")
    format("woff2");
  /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: "MyWebFont";
  src: url("./assets/fonts/KFOlCnqEu92Fr1MmYUtfBBc4AMP6lQ.woff2")
    format("woff2");
  /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: "MyWebFont";
  src: url("./assets/fonts/materialdesignicons-webfont.woff2") format("woff2");
  /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}

@font-face {
  font-family: "Poppins Thin";
  src: url(~@/assets/fonts/Poppins-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins Light";
  src: url(~@/assets/fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins Medium";
  src: url(~@/assets/fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "DM Bold";
  src: url(~@/assets/fonts/DMSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "DM Regular";
  src: url(~@/assets/fonts/DMSans-Regular.ttf) format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");

.v-sheet.v-card:not(.v-sheet--outlined), .btn-theme {
  box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.1) !important;
}

@media (min-width: 427px) {
  .show-mobile {
    display: none !important;
  }
}
@media (max-width: 426px) {
  .hide-desktop {
    display: none;
  }
}

@media (min-width: 600px) {
  .show-mobile-ex {
    display: none;
  }
}
@media (max-width: 599px) {
  .hide-desktop-ex {
    display: none;
  }
  .truncate-text {
    text-overflow: ellipsis !important;
    max-width: 150px !important;
    overflow: hidden;
    color: #4dba87;
  }
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: inherit;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.maxWidth {
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 100px;
}

.theme--light.v-file-input .v-file-input__text {
  color: var(--font-color) !important;
}

@media (max-width: 960px) {
  .maxWidth {
    max-width: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
