import { lazyLoadNavRoutes, lazyLoadView, ifKYC } from './controller';

export const social =
{
  path: "/social",
  component: lazyLoadView('social'),
  beforeEnter: ifKYC,
  children: [
    {
      path: "",
      name: "index",
      component: lazyLoadNavRoutes('social/index')
    },
    {
      path: "overview/:id",
      name: "overview",
      component: lazyLoadNavRoutes('social/overview')
    },
    {
      path: "active",
      name: "active",
      component: lazyLoadNavRoutes('social/active')
    }
  ],
}
