// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
  Partnership: {},
  numberOfRows: 0,
  itemPerPage: 10,
  currentPage: 1,
  LeadNumberOfRows: 0,
  LeadItemPerPage: 10,
  newLeadCurrentPage: 1,
}

const getters = {}

const actions = {
  becomeAnIB: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen')
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/become-ib`, data).then(response => {
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  isAnIB: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen')
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/ib-dashboard`, data).then(response => {
        // send all response to store
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  cashOut: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen')
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/partners/cash-out`, data).then(response => {
        // send all response to store
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  wireTransfer: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/partners/wire-transfer`, data).then(response => {
        // send all response to store
        context.commit('setIB', response.data.data)
        resolve(response)
      })
        .catch(error => {
          context.commit("doNothing");
          // reject all response to store
          reject(error);
        })
    })
  },

  internalTransfer: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/partners/internal-transfer`, data).then(response => {
        // send all response to store
        context.commit('setIB', response.data.data)
        resolve(response)
      })
        .catch(error => {
          context.commit("doNothing");
          // reject all response to store
          reject(error);
        })
    })
  },

  parnerClient: (context) => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/partner-clients/${state.itemPerPage}`, data).then(response => {
        // send all response to store
        resolve(response)
        // send all response to store
        context.commit('setNumbersOfRow', response.data.num_rows)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },
  parnersLead: (context) => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/partner-leads/${state.LeadItemPerPage}`, data).then(response => {
        // send all response to store
        resolve(response)
        // send all response to store
        context.commit('setLeadNumbersOfRow', response.data.num_rows)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  searchClientByEmail: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/partner-clients-search`, data).then(response => {
        // send all response to store
        resolve(response)
        // send all response to store
        context.commit('setNumbersOfRow', response.data.num_rows)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  searchLeadByEmail: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/partner-leads-search`, data).then(response => {
        // send all response to store
        resolve(response)
        // send all response to store
        context.commit('setLeadNumbersOfRow', response.data.num_rows)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  parnerWithdrawal: (context) => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/partner-withdrawals/${state.itemPerPage}`, data).then(response => {
        // send all response to store
        context.commit('setNumbersOfRow', response.data.num_rows)
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  partnerBanners: (context) => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/partner-banners`, data).then(response => {
        // send all response to store
        context.commit('setNumbersOfRow', response.data.num_rows)
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },
}

const mutations = {
  setIB: (state, data) => {
    state.Partnership = data
  },
  // commit numbers of rows
  setNumbersOfRow: (state, data) => (state.numberOfRows = data),
  setLeadNumbersOfRow: (state, data) => (state.LeadNumberOfRows = data),
  // increment itemPerScroll
  setItemPerPage: (state) => (state.itemPerPage += 10),
  setLeadItemPerPage: (state) => (state.LeadItemPerPage += 10),
  decreaseItemPerPage: (state) => (state.itemPerPage -= 10),
  decreaseLeadItemPerPage: (state) => (state.LeadItemPerPage -= 10),
  setCurrentPage: (state) => (state.currentPage += 1),
  setLeadCurrentPage: (state) => (state.newLeadCurrentPage += 1),
  removeCurrentPage: (state) => (state.currentPage -= 1),
  removeLeadCurrentPage: (state) => (state.newLeadCurrentPage -= 1),
}

export default {
  //export all the listed properties
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
