// import helper controller functions
import { ifKYC, lazyLoadView, lazyLoadNavRoutes } from './controller';

export const profile = {
    // main route for profile
    path: "/profile",
    component: lazyLoadView('profile'),
    beforeEnter: ifKYC,
    // child route for profile
    children: [
        { path: "", name: "profile", component: lazyLoadNavRoutes('profiles/bioDetails') },
        {
            path: "bankingDetails",
            name: "bankingDetails",
            component: lazyLoadNavRoutes('profiles/bankingDetails')
        },
        { path: "security", name: "security", component: lazyLoadNavRoutes('profiles/security') },
        {
            path: "yourActivity",
            name: "yourActivity",
            component: lazyLoadNavRoutes('profiles/yourActivity')
        }
    ]
}