// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
  Kyc: [],
  error: "",
  isPrembly: undefined,
  isPremblyStaging: undefined,
}

const getters = {
  getIsPrembly: (state) => {
    return state.isPrembly
  },

  getPremblyStaging: (state) => {
    return state.isPremblyStaging
  },

}

const actions = {
  isPrembly: (context) => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`kyc/is-prembly`, data).then(response => {
        context.commit('setIsPrembly', response.data.data)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  isPrembly_staging: (context) => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`kyc/is-prembly-staging`, data).then(response => {
        context.commit('setPremblyStaging', response.data.data)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  isPrembly_channel: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`kyc/channel`, data).then(response => {
        resolve(response);
      }).then(error => {
        reject(error)
      })
    })
  },

  isPrembly_channel_staging: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`kyc/channel-staging`, data).then(response => {
        resolve(response);
      }).then(error => {
        reject(error)
      })
    })
  },

  step1: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`kyc/step1-v2`, data).then(response => {
        context.commit('setKyc', response.data.data)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  step1_old: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`kyc/step1-old`, data).then(response => {
        context.commit('setKyc', response.data.data)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  step2: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`kyc/step2-v2`, data).then(response => {
        context.commit('setKyc', response.data.data)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  step2_old: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`kyc/step2`, data).then(response => {
        context.commit('setKyc', response.data.data)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  step2_v1: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`kyc/step2-new`, data).then(response => {
        context.commit('setKyc', response.data.data)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  step3: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`kyc/step3`, data).then(response => {
        context.commit('setKyc', response.data.data)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  step3_staging: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`kyc/step3-new`, data).then(response => {
        context.commit('setKyc', response.data.data)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },

  errorMsg: (context) => {
    return new Promise((resolve, reject) => {
      const data = {
        client_id: localStorage.getItem('cID'),
        session: localStorage.getItem('aen'),
      }
      onboardingHttpClient.post(`kyc/rejection-message`, data).then(response => {
        context.commit('setKyc', response.data.data)
        resolve(response)
      }).then(error => {
        reject(error)
      })
    })
  },
}

const mutations = {
  setKyc: (state, data) => {
    state.Kyc = data
  },
  setIsPrembly: (state, data) => {
    const isPrembly = data === undefined ? false : data.is_prembly;
    state.isPrembly = isPrembly;
  },
  setPremblyStaging: (state, data) => {
    const isPrembly = data === undefined ? false : data.is_prembly;
    state.isPremblyStaging = isPrembly;
  }
}

export default {
  //export all the listed properties
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};