// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
  instantDepo: "",
  OfflineDepo: [],
  CancelDepo: [],
  ConfirmDepo: [],
  UploadDepoSlip: [],
  ProcessedHistory: [],
  itemPerPage: 10,
  numberOfRows: 0,
  currentPage: 1,
  dateRange: {
    startDate: '',
    endDate: '',
  },
};

const actions = {
  instantDepo: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/deposit`, data).then(response => {
        // send all response to store
        context.commit('setInstantDepo', response.data.data)
        resolve(response)
      })
        .catch(error => {
          context.commit("doNothing");
          // reject all response to store
          reject(error);
        })
    })
  },

  offlineDepo: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/deposit-details`, data).then(response => {
        // send all response to store
        context.commit('setOfflineDepo', response.data.data)
        resolve(response)
      })
        .catch(error => {
          context.commit("doNothing");
          // reject all response to store
          reject(error);
        })
    })
  },

  unconfirmedDepo: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen')
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/deposits-unconfirmed`, data).then(response => {
        // send all response to store
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  confirmDepo: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/confirm-deposit`, data).then(response => {
        // send all response to store
        context.commit('setConfirmDepo', response.data.data)
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  ListConfirmed: () => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen')
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/deposits-confirmed`, data).then(response => {
        // send all response to store
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  cancelDepo: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/cancel-deposit`, data).then(response => {
        // send all response to store
        context.commit('setCancelDepo', response.data.data)
        resolve(response)
      })
        .catch(error => {
          // reject all response to store
          reject(error);
        })
    })
  },

  uploadDepoSlip: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/deposit-add-slip`, data).then(response => {
        context.commit('setUploadDepoSlip', response)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  depoHistory: (context) => {
    const data = {
      client_id: localStorage.getItem('cID'),
      session: localStorage.getItem('aen'),
      start_date: state.dateRange.startDate ? state.dateRange.startDate : "",
      end_date: state.dateRange.endDate ? state.dateRange.endDate : ""
    }
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/deposit-processed-history/${state.itemPerPage}`, data).then(response => {
        resolve(response)
        context.commit('setNumbersOfRow', response.data.num_rows)
      }).catch(error => {
        reject(error)
      })
    })
  }


};

const getters = {};

const mutations = {
  // filter by date
  filterRange: (state, data) => { state.dateRange = data },

  setInstantDepo: (state, data) => {
    state.instantDepo = data
  },
  setOfflineDepo: (state, data) => {
    state.OfflineDepo = data
  },
  setCancelDepo: (state, data) => {
    state.CancelDepo = data
  },
  setConfirmDepo: (state, data) => {
    state.ConfirmDepo = data
  },
  setUploadDepoSlip: (state, data) => {
    state.UploadDepoSlip = data
  },
  setProcessedHistory: (state, data) => {
    state.ProcessedHistory = data
  },
  // commit numbers of rows
  setNumbersOfRow: (state, data) => (state.numberOfRows = data),
  // increment itemPerScroll
  setItemPerPage: (state) => (state.itemPerPage += 10),
  decreaseItemPerPage: (state) => (state.itemPerPage -= 10),
  setCurrentPage: (state) => (state.currentPage += 1),
  returnItemToTen: (state, data) => (state.itemPerPage = data),
  returnToPageOne: (state, data) => (state.currentPage = data),
  removeCurrentPage: (state) => (state.currentPage -= 1),
};

export default {
  //export all the listed properties
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
