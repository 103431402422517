// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
  withdraw: ''
}

const getters = {}

const actions = {
  withdrawDepo: (context, data) => {
    return new Promise((resolve, reject) => {
      onboardingHttpClient.post(`/withdraw`, data).then(response => {
        // send all response to store
        context.commit('setWithdraw', response.data.data)
        resolve(response)
      })
        .catch(error => {
          context.commit("doNothing");
          // reject all response to store
          reject(error);
        })
    })
  }
}

const mutations = {
  setWithdraw: (state, data) => {
    state.withdraw = data
  }
}

export default {
  //export all the listed properties
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
