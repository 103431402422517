// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
    // loaing is triggerred on true
    loading: false,
    // store quotes of the day
    quoteOfTheDay: [],
    // store last three event
    last3Event: [],
    // store last 3 announcements
    last3Announcemts: [],
    // store last promo
    lastPromo: {},
    // store client demo acct
    demoAccount: [],
    // store client real acct
    realAccount: [],
};

const getters = {
    getRealAccount: state => state.realAccount,
    getDemoAccount: state => state.demoAccount,
};

const mutations = {
    // commit loading
    setLoading(state, payload) {
        state.loading = payload;
    },
    // commit demo acct
    setDemoAcct(state, data) {
        state.demoAccount = data
    },
    // commit real acct
    setRealAcct(state, data) {
        state.realAccount = data
    },
    // commit nothing
    doNothing: (state) => (state.doNothing = null),
};

const actions = {

    QuotesOfTheDay: () => {
        const data = {
            client_id: localStorage.getItem('cID'),
            session: localStorage.getItem('aen')
        }
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/dashboard/quote-of-the-day", data).then(response => {
                // send all response to store
                resolve(response)
            })
                .catch(error => {
                    // reject all response to store
                    reject(error);
                })
        })
    },

    LastThreeEvents: () => {
        const data = {
            client_id: localStorage.getItem('cID'),
            session: localStorage.getItem('aen')
        }
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/dashboard/last-3-events", data).then(response => {
                // send all response to store
                resolve(response)
            })
                .catch(error => {
                    // reject all response to store
                    reject(error);
                })
        })
    },

    LastThreeAnnouncements: () => {
        const data = {
            client_id: localStorage.getItem('cID'),
            session: localStorage.getItem('aen')
        }
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/dashboard/last-3-announcements", data).then(response => {
                // send all response to store
                resolve(response)
            })
                .catch(error => {
                    // reject all response to store
                    reject(error);
                })
        })
    },

    LastPromo: () => {
        const data = {
            client_id: localStorage.getItem('cID'),
            session: localStorage.getItem('aen')
        }
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/dashboard/last-promotion", data).then(response => {
                // send all response to store
                resolve(response)
            })
                .catch(error => {
                    // reject all response to store
                    reject(error);
                })
        })
    },

    DemoAccount: (context) => {
        const data = {
            client_id: localStorage.getItem('cID'),
            session: localStorage.getItem('aen')
        }

        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/dashboard/demo-accounts", data).then(response => {
                // send all response to store
                context.commit('setDemoAcct', response.data.data)
                resolve(response)
            })
                .catch(error => {
                    context.commit("doNothing");
                    // reject all response to store
                    reject(error);
                })
        })
    },
    RealAccount: (context) => {
        const data = {
            client_id: localStorage.getItem('cID'),
            session: localStorage.getItem('aen')
        }

        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/dashboard/real-accounts", data).then(response => {
                // send all response to store
                context.commit('setRealAcct', response.data.data)
                resolve(response)
            })
                .catch(error => {
                    context.commit("doNothing");
                    // reject all response to store
                    reject(error);
                })
        })
    },

};

export default {
    //export all the listed properties
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};