import axios from "axios";

const NgnBankInArr = "https://www.rally.trade/api/arrays/banks/ngn"
const USBankInArr = "https://www.rally.trade/api/arrays/banks/usd"

const state = {
  demoAccountType: [
    {
      hint: "Basic",
      topic:
        "Perfectly suited for inexperienced traders starting their journey in Capital Markets",
      valueDeterminate: 45,
      color: "deep-purple accent-4",
      colorText: "deep-purple--text accent-4",
      id: "demobasic"
    },
    {
      hint: "Standard",
      topic:
        "The balanced account type offering low spreads and top execution",
      valueDeterminate: 70,
      color: "warning accent-4",
      colorText: "warning--text accent-4",
      id: "demostandard"
    },
    {
      hint: "Professional",
      topic: "Designed for experienced traders that leverage on high volume trading",
      valueDeterminate: 100,
      color: "success accent-4",
      colorText: "success--text accent-4",
      id: "demopro"
    }
  ],

  realAccountType: [
    {
      hint: "Basic",
      topic:
        "Perfectly suited for inexperienced traders starting their journey in Capital Markets",
      valueDeterminate: 45,
      color: "deep-purple accent-4",
      colorText: "deep-purple--text accent-4",
      id: "realbasic"
    },
    {
      hint: "Standard",
      topic: "The balanced account type offering low spreads and top execution",
      valueDeterminate: 70,
      color: "warning accent-4",
      colorText: "warning--text accent-4",
      id: "realstandard"
    },
    {
      hint: "Professional",
      topic: "Designed for experienced traders that leverage on high volume trading",
      valueDeterminate: 100,
      color: "success accent-4",
      colorText: "success--text accent-4",
      id: "realpro"
    },
    {
      hint: "RallyXchange",
      topic: "Get more $ value for your ₦",
      valueDeterminate: 100,
      color: "info accent-4",
      colorText: "info--text accent-4",
      id: "realrx"
    },
  ],

  chipCard: [],

  depositeType: [
    {
      cols: 3,
      size: "190",
      routes: "instantBankDetails",
      image: require("@/assets/images/general/credit-card.svg"),
      text: "INSTANT",
      disabled: false,
    },
    {
      cols: 3,
      size: "190",
      routes: "posBankDetails",
      image: require("@/assets/images/general/pos.png"),
      text: "POS",
      disabled: false
    },
    {
      cols: 3,
      size: "190",
      routes: "wireBankDetails",
      image: require("@/assets/images/general/wire-deposite.svg"),
      text: "WIRE",
      disabled: true
    },
    {
      cols: 3,
      size: "190",
      routes: "cashBankDetails",
      image: require("@/assets/images/general/teller.svg"),
      text: "CASH",
      disabled: true
    }
  ],

  selectFRNGBank: [
    {
      text: "GTbank",
      icon: require("@/assets/images/bank-logo/gtbank.jpg"),
      class: "my-2 pa-1",
      acctName: "FRNG LIMITED-CLIENT'S ACCOUNT",
      deposit: "GT Deposit",
      acctNameDollars: "FRNG LIMITED-CLIENT'S ACCOUNT",
      acctNum: "0198832466",
      accountDollars: "0198833401",
      id: "001"
    },
    {
      text: "Zenith",
      icon: require("@/assets/images/bank-logo/zenith.png"),
      class: "my-2 pa-1",
      acctName: "FRNG LIMITED (CLIENT ACCT)",
      deposit: "Zenith Deposit",
      acctNameDollars: "FRNG LIMITED SA",
      acctNum: "1014414254",
      accountDollars: "5070490508",
      id: "002"
    },
    {
      text: "Wema",
      icon: require("@/assets/images/bank-logo/wema.png"),
      class: "mt-4 mb-4 pa-1",
      acctName: "FRNG LIMITED CLIENT ACCOUNT",
      deposit: "Wema Deposit",
      acctNum: "0122598731",
      id: "003"
    },
    {
      text: "Monie Point",
      icon: require("@/assets/images/bank-logo/moniepoint.png"),
      class: "mt-4 mb-4 pa-1",
      acctName: "FRNG LTD  Rally Trade",
      deposit: "MoniePoint Deposit",
      acctNum: "8260641345",
      id: "004"
    },

    {
      text: "Heritage",
      icon: require("@/assets/images/bank-logo/heritage.jpg"),
      class: "my-2 pa-1",
      acctName: "FRNG LIMITED (CLIENT ACCT)",
      acctNum: "1014414254",
      id: "005"
    },
    {
      text: "Providus",
      icon: require("@/assets/images/bank-logo/providus.png"),
      class: "my-2 pa-1",
      acctName: "FRNG LIMITED (CLIENT ACCT)",
      acctNum: "1014414254",
      id: "006"
    },
  ],
  tour: [
    {
      target: "#v-step-0",
      content:
        `<span class='text-body-1'>It is important to fill in the remarks or comments field the<div class='orange--text '>RallyTrade trading account number and your full name`,
      params: {
        placement: "top" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
      }
    },
    {
      target: "#v-step-1",
      content: "<span class='text-caption'>RallyTrade Bank Account Name</span>"
    },
    {
      target: "#v-step-2",
      content: "<span class='text-caption'>RallyTrade Bank Account Number</span>"
    },
    {
      target: "#v-step-3",
      content: "<span class='text-caption'>Amount to transfer</span>"
    },
    {
      target: "#v-step-4",
      content: "<span class='text-caption'>RallyTrade trading account number and your full name</span>"
    }
  ],

  RallyUSBankAcct: []
};
const getters = {
  getRealAccountType: state => state.realAccountType,
  getDemoAccountType: state => state.demoAccountType,
  getFRNGBank: state => state.selectFRNGBank,
  getAcct: state => state.depositeType,
  getTour: state => state.tour,
  getChipCard: state => state.chipCard,
  getSelectBank: state => state.selectBank,
};

const actions = {
  NGNBank: (context) => {
    return new Promise((resolve, reject) => {
      axios.get(NgnBankInArr).then(response => {
        context.commit('setNgnBank', response.data.data)
        resolve(response.data.data)
      }).catch(err => {
        reject(err)
      })
    })
  },

  USBank: (context) => {
    return new Promise((resolve, reject) => {
      axios.get(USBankInArr).then(response => {
        context.commit('setRallyUSBank', response.data.data)
        resolve(response.data.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
};

const mutations = {
  setChipCard(state, data) {
    state.chipCard.unshift(data);
  },
  setNgnBank(state, data) {
    state.selectBank = data
  },
  setRallyUSBank(state, data) {
    state.RallyUSBankAcct = data
  },
};

export default {
  //export all the listed properties
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
