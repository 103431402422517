import Vue from "vue";
import App from './App.vue';
import router from "./router";
import store from "./store";
import VueTour from "vue-tour";
import vuetify from "./plugins/vuetify";
import './registerServiceWorker';

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
  
    render: function(h) {
      return h(App);
    }
  }).$mount("#app");