import onboardingHttpClient from "@/axios/onboarding.service";
import destroyLocalStorage from "@/mixins/destroyLocalStorage";

const state = {
    name: localStorage.getItem('name') || null,
    email: localStorage.getItem('email') || null,
    // get the accessToken from localstorage and update the state
    accessToken: localStorage.getItem('aen') || null,
    // get the clientID from localstorage and update the state
    clientID: localStorage.getItem('cID') || null,
    // store the server status from the server
    serverStatus: localStorage.getItem('status') || null,
    // check if client is IB
    I_B: localStorage.getItem('is_B') || null,
    social: localStorage.getItem('social') || null,
    notification: localStorage.getItem('newInfo') || null,
    doNothing: null,
    errorTracker: {
        message: "",
        error: false,
        session: false
    },
};

const getters = {};

const actions = {
    //allows users to login
    signIn: (context, data) => {
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/login", data).then(response => {

                // commit changes to mutation
                context.commit("setClientID", response.data.error_message ? '' : response.data.data.client_id)
                context.commit('setServerStatus', response.data.error_message ? '' : response.data.status)
                context.commit('setEmail', response.data.error_message ? '' : response.data.data)
                // commit changes to the mutation
                context.commit("setAccessToken", response.data.error_message ? '' : response.data.session);
                // commit changes to mutation
                context.commit("setKycStatus", response.data.error_message ? '' : response.data.data.client_kyc_status)
                // if a client is an IB
                context.commit("setIB", response.data.error_message ? '' : response.data.data.client_partner_type)
                // if a client is in social
                context.commit("setSocial", response.data.error_message ? '' : response.data.data.client_social_type)
                // collect notification
                context.commit("setNotification", response.data.error_message ? '' : response.data.data.new_info)
                // send all response to store
                resolve(response)
            })
                .catch(error => {
                    // remove clientID and accessToken from local storage
                    destroyLocalStorage();
                    // reject all response to store
                    reject(error);
                })
        })
    },

    forgotPassword: (context, data) => {
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/forgot-password", data).then(response => {
                // send all response to store
                resolve(response)
            })
                .catch(error => {
                    context.commit("doNothing");
                    // reject all response to store
                    reject(error);
                })
        })
    },

    changePassword: (context, data) => {
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/change-password", data).then(response => {
                // send all response to store
                resolve(response)
            })
                .catch(error => {
                    context.commit("doNothing");
                    // reject all response to store
                    reject(error);
                })
        })
    },
};

const mutations = {
    // set client ID 
    setClientID: (state, clientID) => {
        // set clientID to localStorage
        clientID ? localStorage.setItem('cID', clientID) : ''
        // get the clientID from localstorage and update the state
        state.clientID = clientID
    },
    // set access token
    setAccessToken: (state, token) => {
        // set access token to localStorage
        token ? localStorage.setItem('aen', token) : ''
        state.accessToken = token
    },
    setServerStatus: (state, status) => {
        localStorage.setItem('status', status)
        state.serverStatus = status
    },
    setEmail: (state, data) => {
        localStorage.setItem('name', data.client_last_name)
        localStorage.setItem('email', data.client_email)
        state.email = data.client_email
        state.name = data.client_last_name
    },
    setKycStatus: (state, data) => {
        localStorage.setItem('cStatus', data)
        state.kycStatus = data
    },
    setIB: (state, data) => {
        if (data == "ib")
            localStorage.setItem('is_B', true)
        else
            localStorage.setItem('is_B', false)

        state.I_B = data
    },
    setSocial: (state, data) => {
        localStorage.setItem('social', data)
        state.social = data
    },
    setNotification: (state, data) => {
        localStorage.setItem('newInfo', data)
        state.notification = data
    },
    // set error messages, this handles error message globally
    setErrorTracker: (state, errorInfo) => {
        state.errorTracker.message = errorInfo.message;
        state.errorTracker.error = errorInfo.error;
        state.errorTracker.session = errorInfo.session
    },
    // commit nothing
    doNothing: (state) => (state.doNothing = null),
};

export default {
    //export all the listed properties
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};