import axios from "axios";
import store from "../store";

// base url for development environment
let onboardingBaseUrl = "https://www.rally.trade/api/co";

// set base url to production if environment is production
// if (process.env.NODE_ENV == "production") onboardingBaseUrl = "";

const onboardingHttpClient = axios.create({
    baseURL: onboardingBaseUrl,
    timeout: 600000,
});

// Add a request interceptor
onboardingHttpClient.interceptors.request.use(function (config) {
    // Do something before request is sent
    // pass sessionID and clientID to every request 

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
onboardingHttpClient.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.status >= 1 && response.data.error_type == "session") {
        // commit("off") last, after fetch data or more.
        store.commit("dashboard/setLoading", false);
        store.commit("onboarding/setErrorTracker", {
            message: response.data.error_message,
            session: true
        })
    }
    return response;
}, function (error) {
    if (error.response.status >= 500)
        // commit("off") last, after fetch data or more.
        store.commit("dashboard/setLoading", false);
        store.commit("onboarding/setErrorTracker", {
            message: error.message,
            error: true
        })
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

export default onboardingHttpClient;