import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // 'App is being served from cache by a service worker.\n' +
      // 'For more details, visit https://goo.gl/AFskqB'
      Notification.requestPermission(function (status) {
        if (status === 'granted') {
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification('New Client Office', {
              body: 'Rally Trade has launched a new client office',
              icon: './assets/images/RallyTrade_Logo_w.png',
              vibrate: [200, 100, 200, 100, 200, 100, 200],
              tag: 'vibration-sample'
            });
          });
        }
      })
    },
    registered() {
      // 'Service worker has been registered.'
    },
    cached() {
      // 'Content has been cached for offline use.'
    },
    updatefound() {
      // 'New content is downloading.'
    },
    updated(registration) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      // document.dispatchEvent(
      //   new CustomEvent('swUpdated', { detail: registration })
      // )
      // 'New content is available; please refresh.'
    },
    offline(registration) {
      document.dispatchEvent(
        new CustomEvent('swOffline', { detail: registration })
      )
      // 'No internet connection found. App is running in offline mode.'
    },
    error(error) {
      console.log('progressive web app error', error)
      // 'Error during service worker registration:', error
    }
  })
}
