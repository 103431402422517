// gets the base url from axios
import onboardingHttpClient from "@/axios/dashboard.service";

const state = {
    // creare accts
    createAcct: [],
    acctDetails: [],
    LiveRealAcct: [],
    doNothing: [],
    InternalTransfer: [],
    ProcessedHistory: [],
    itemPerPage: 10,
    numberOfRows: 0,
    currentPage: 1,
};

const getters = {
    getLiveRealAccount: state => state.LiveRealAcct
};

const mutations = {
    setAcct(state, data) {
        state.createAcct = data
    },
    setAcctDetails(state, data) {
        state.acctDetails = data
    },
    setLiveRealAcct(state, data) {
        state.LiveRealAcct = data
    },
    setInternalTransfer(state, data) {
        state.InternalTransfer = data
    },
    setProcessedHistory(state, data) {
        state.ProcessedHistory = data
    },
    // set page pagination
    setItemPerPage: (state) => (state.itemPerPage += 10),
    decreaseItemPerPage: (state) => (state.itemPerPage -= 10),
    setNumberOfRows: (state, data) => (state.numberOfRows = data),
    setCurrentPage: (state) => (state.currentPage += 1),
    removeCurrentPage: (state) => (state.currentPage -= 1),
    // commit nothing
    doNothing: (state) => (state.doNothing = null),
};

const actions = {
    CreateAccount: (context, data) => {
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/open-account", data).then(response => {
                // send all response to store
                context.commit('setAcct', response.data.data)
                resolve(response)
            })
                .catch(error => {
                    context.commit("doNothing");
                    // reject all response to store
                    reject(error);
                })
        })
    },

    AccountDetails: (context, data) => {
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/account-info", data).then(response => {
                // send all response to store
                context.commit('setAcctDetails', response.data.data)
                resolve(response)
            })
                .catch(error => {
                    context.commit("doNothing");
                    // reject all response to store
                    reject(error);
                })
        })
    },

    InternalTransfer: (context, data) => {
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post("/internal-transfer", data).then(response => {
                // send all response to store
                context.commit('setInternalTransfer', response.data.data)
                resolve(response)
            })
                .catch(error => {
                    context.commit("doNothing");
                    // reject all response to store
                    reject(error);
                })
        })
    },

    ProcessedHistory: (context, data) => {
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post(`/account-processed-history/${state.itemPerPage}`, data).then(response => {
                // send all response to store
                context.commit('setProcessedHistory', response.data.data)
                context.commit('setNumberOfRows', response.data.num_rows)
                resolve(response)
            })
                .catch(error => {
                    context.commit("doNothing");
                    // reject all response to store
                    reject(error);
                })
        })
    },

    AcctInfoLive: (context) => {
        const data = {
            client_id: localStorage.getItem('cID'),
            session: localStorage.getItem('aen')
        }
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post(`/accounts-info-live`, data).then(response => {
                // send all response to store
                resolve(response)
                context.commit('setLiveRealAcct', response.data.data)
            })
                .catch(error => {
                    // reject all response to store
                    reject(error);
                })
        })
    },

    ConversionRate: () => {
        return new Promise((resolve, reject) => {
            onboardingHttpClient.get('https://www.rally.trade/api/arrays/ngnperusd').then(response => {
                resolve(response);
            }).catch(error => {
                reject(error)
            })
        }) 
    },

    withdralCalculateRxAmount: (context, data) => {
        return new Promise((resolve, reject) => {
            onboardingHttpClient.post('/withdraw-calculate-rx-amount', data).then(response => {
                resolve(response);
                context.commit('doNothing')
            }).catch(error => {
                reject(error)
            })
        }) 
    }
};

export default {
    //export all the listed properties
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};